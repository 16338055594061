<template>
  <div class="progress-bar-component">
    <div class="question-mark">
      <b-icon icon="info-circle" @click="toggleDescription"></b-icon>
    </div>
    <div v-if="descriptionVisible" class="description-container">
      {{ description }}
    </div>
    <div
      class="label"
      :style="{
        fontWeight: strongLabel ? 'bold' : 'normal',
        fontSize: strongLabel ? '1.4rem' : '1rem',
      }"
    >
      {{ label }}
    </div>
    <div class="progress-wrapper">
      <div
        class="progress-bar primary-bar"
        :style="{ width: primaryBarWidth + '%' }"
      ></div>
      <div
        class="progress-bar secondary-bar"
        :style="{ width: secondaryBarWidth + '%' }"
      ></div>
    </div>
    <div v-if="descriptionVisible" class="description-container">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    strongLabel: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      required: true,
    },
    tot: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      tooltip: {
        visible: false,
      },
      descriptionVisible: false,
    };
  },
  computed: {
    primaryBarWidth() {
      return (this.value / this.tot) * 100;
    },
    secondaryBarWidth() {
      return 100;
    },
    tooltipText() {
      return `${this.label}: ${this.value} / ${this.tot}`;
    },
  },
  methods: {
    toggleDescription() {
      this.descriptionVisible = true;
      setTimeout(() => {
        this.descriptionVisible = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.progress-bar-component {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.label {
  flex: 0 0 30%;
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.progress-bar {
  height: 20px;
  border-radius: 10px;
}

.primary-bar {
  background-color: var(--whappy-primary);
}

.secondary-bar {
  background-color: var(--whappy-secondary);
}

.description-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0.444);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
  border-radius: 10px;
}
.question-mark {
  position: absolute;
  top: -10px;
  right: 0px;
  cursor: pointer;
}
</style>
