export const stats = {
  linechartDataset: [
    {
      label: "Andamento RST",
      data: [10, 20, 30, 25, 35, 45],
    },
    {
      label: "Andamento KPA",
      data: [15, 25, 35, 30, 40, 50],
    },
    // Add more datasets as needed
  ],
  bubbleDatasets: [
    {
      label: "Media RST",
      data: [
        { x: 10, y: 20, r: 15 },
        { x: 30, y: -40, r: -25 },
        { x: 50, y: -60, r: 20 },
        { x: -70, y: 80, r: 10 },
        { x: 90, y: -100, r: 5 },
        { x: 110, y: -120, r: 10 },
        { x: -130, y: 140, r: -15 },
        { x: 150, y: 160, r: -20 },
        { x: 170, y: -180, r: -25 },
        { x: -190, y: 200, r: 30 },
        { x: 210, y: 220, r: 35 },
      ],
    },
    {
      label: "KPA %",
      data: [
        { x: 15, y: 25, r: 10 },
        { x: 35, y: 45, r: 30 },
        { x: 55, y: -65, r: 15 },
        { x: 75, y: 85, r: 5 },
        { x: 95, y: 105, r: -10 },
        { x: 115, y: 125, r: 15 },
        { x: 135, y: 145, r: 20 },
        { x: 155, y: -165, r: 25 },
        { x: -175, y: 185, r: 30 },
        { x: -195, y: -205, r: 35 },
        { x: 215, y: 225, r: 40 },
      ],
    },
  ],
  risposteGiusteSuTotali: [
    {
      label: "Total Answers",
      data: [
        72,
        65,
        53,
        48,
        74,
        76,
        66,
        85,
        77,
        68,
        89,
        95,
        45,
        60,
        64,
        58,
        83,
        92,
        88,
        79,
        80,
        81,
        56,
        55,
        40,
        70,
        91,
        63,
        57,
        59,
      ],

      tension: 0.1,
    },
    {
      label: "Correct Answers",
      data: [
        28,
        48,
        40,
        19,
        26,
        27,
        20,
        65,
        32,
        17,
        48,
        38,
        14,
        21,
        19,
        54,
        31,
        23,
        6,
        76,
        39,
        43,
        12,
        34,
        25,
        50,
        18,
        11,
        9,
        24,
      ],

      tension: 0.5,
    },
  ],
  taskCompletateSuAssegnate: [
    {
      label: "Assigned Tasks",
      data: [
        78,
        62,
        90,
        85,
        69,
        68,
        50,
        80,
        55,
        75,
        85,
        65,
        59,
        95,
        74,
        64,
        88,
        76,
        92,
        86,
        75,
        93,
        98,
        82,
        73,
        66,
        94,
        97,
        90,
        83,
      ],

      tension: 0.1,
    },
    {
      label: "Completed Tasks",
      data: [
        35,
        52,
        45,
        25,
        35,
        30,
        25,
        72,
        40,
        24,
        50,
        42,
        19,
        78,
        25,
        60,
        38,
        28,
        12,
        82,
        48,
        52,
        18,
        40,
        50,
        55,
        25,
        18,
        15,
        30,
      ],

      tension: 0.5,
    },
  ],
  datasets: [
    {
      label: "Accesses",
      data: [
        14,
        32,
        17,
        34,
        45,
        50,
        48,
        31,
        89,
        40,
        38,
        6,
        9,
        90,
        86,
        76,
        18,
        21,
        19,
        54,
        12,
        65,
        43,
        19,
        27,
        24,
        71,
        28,
        48,
        23,
      ],
      fill: false,
      backgroundColor: "#223352",
      borderColor: "#223352",
      hoverOffset: 0.1,
    },
  ],
  sessioniAttive: [
    {
      label: "Sessions",
      data: [
        28,
        48,
        40,
        19,
        86,
        27,
        90,
        65,
        32,
        17,
        48,
        38,
        14,
        71,
        19,
        54,
        31,
        23,
        6,
        76,
        89,
        43,
        12,
        34,
        45,
        50,
        18,
        21,
        9,
        24,
      ],
      fill: false,
      borderColor: "rgb(255, 99, 132)",
      tension: 0.1,
    },
  ],
  mediaCapitoliCompletatiPerUtente: [
    {
      label: "Chapters",
      data: [
        6,
        9,
        43,
        19,
        27,
        90,
        86,
        76,
        14,
        12,
        65,
        24,
        34,
        45,
        50,
        71,
        28,
        48,
        23,
        32,
        17,
        48,
        18,
        21,
        31,
        89,
        40,
        38,
        19,
        54,
      ],
      fill: false,
      borderColor: "rgb(255, 99, 132)",
      tension: 0.1,
    },
  ],
  mediaTaskEseguitePerUtente: [
    {
      label: "Task",
      data: [
        28,
        48,
        23,
        6,
        43,
        12,
        34,
        45,
        50,
        9,
        19,
        27,
        90,
        65,
        32,
        17,
        48,
        18,
        21,
        24,
        86,
        76,
        89,
        40,
        38,
        14,
        71,
        19,
        54,
        31,
      ],
      fill: false,
      borderColor: "rgb(255, 99, 132)",
      tension: 0.1,
    },
  ],
  mediaKpiPerUtente: [
    {
      label: "Kpis",
      data: [
        6,
        43,
        19,
        90,
        9,
        27,
        86,
        76,
        12,
        34,
        14,
        65,
        23,
        24,
        45,
        71,
        50,
        28,
        32,
        48,
        18,
        21,
        31,
        40,
        89,
        19,
        38,
        48,
        17,
        54,
      ],
      fill: false,
      borderColor: "rgb(255, 99, 132)",
      tension: 0.1,
    },
  ],
  badgeRiscattati: [
    {
      label: "Giugno",
      data: [
        6,
        4,
        9,
        9,
        9,
        2,
        8,
        7,
        1,
        3,
        1,
        6,
        2,
        2,
        4,
        7,
        5,
        2,
        3,
        4,
        1,
        2,
        3,
        4,
        8,
        1,
        3,
        4,
        1,
        5,
      ],
      fill: false,
      borderColor: "rgb(255, 99, 132)",
      tension: 0.1,
    },
    {
      label: "Luglio",
      data: [
        6,
        1,
        6,
        2,
        2,
        4,
        4,
        1,
        1,
        5,
        7,
        4,
        9,
        9,
        1,
        3,
        5,
        2,
        3,
        9,
        2,
        8,
        2,
        3,
        4,
        8,
        1,
        3,
        4,
        7,
      ],
      fill: false,
      borderColor: "rgb(255, 99, 132)",
      tension: 0.1,
    },
  ],
  mediaCondivisioniPerUtente: [
    {
      label: "Condivisioni",
      data: [
        14,
        32,
        17,
        34,
        45,
        50,
        48,
        31,
        89,
        40,
        38,
        6,
        9,
        90,
        86,
        76,
        18,
        21,
        19,
        54,
        12,
        65,
        43,
        19,
        27,
        24,
        71,
        28,
        48,
        23,
      ],
      fill: false,
      borderColor: "rgb(255, 99, 132)",
      tension: 0.1,
    },
  ],
  apertureGiornaliere: [
    {
      label: "Aperture",
      data: [
        28,
        64,
        35,
        68,
        180,
        225,
        240,
        155,
        178,
        200,
        190,
        12,
        18,
        270,
        258,
        152,
        54,
        42,
        38,
        216,
        24,
        325,
        172,
        38,
        108,
        96,
        213,
        84,
        240,
        69,
      ],
    },
  ],
  socialStacked: [
    {
      label: "Condivisioni",
      data: [120, 200, 150, 150],
    },
  ],
  labels: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ],
  stats: {
    boxes: [
      {
        title: "Perc Login",
        value: 360,
        tot: 400,
        type: "perc",
        decimals: 2,
      },
      {
        title: "Media accessi",
        value: 4,
        type: "number",
        unit: "accessi",
        decimals: 1,
      },
      {
        title: "Perc Login",
        value: 260,
        tot: 400,
        type: "perc",
        decimals: 4,
      },
      {
        title: "Perc Login",
        value: 160,
        tot: 400,
        type: "perc",
      },
      {
        title: "Media durata sessione",
        value: 6,
        type: "number",
        unit: "minuti",
      },
      {
        title: "Media durata sessione",
        value: 10,
        type: "number",
        unit: "minuti",
      },
    ],
  },
  ruoli: [
    {
      label: "Ruoli",
      data: [10, 20, 30],
    },
  ],
  kpiPie: [
    {
      label: "KPI",
      data: [21, 23, 27, 24],
    },
  ],
  radarSkillsTot: {
    labels: ["Support", "Management", "Sales"],
    datasets: [
      {
        label: "Self feedback",
        data: [65, 59, 90],
      },
      {
        label: "Peer feedback",
        data: [28, 48, 40],
      },
      {
        label: "Downward feedback",
        data: [12, 38, 80],
      },
    ],
  },
  radarSkillsSupport: {
    labels: ["Support 1", "Support 2", "Support 3", "Support 4", "Support 5"],
    datasets: [
      {
        label: "Self feedback",
        data: [65, 59, 90, 81, 56],
      },
      {
        label: "Peer feedback",
        data: [28, 48, 40, 19, 96],
      },
      {
        label: "Downward feedback",
        data: [12, 38, 80, 69, 66],
      },
    ],
  },
  radarSkillsManagement: {
    labels: [
      "Management 1",
      "Management 2",
      "Management 3",
      "Management 4",
      "Management 5",
      "Management 6",
      "Management 7",
    ],
    datasets: [
      {
        label: "Self feedback",
        data: [65, 59, 90, 81, 56, 24, 12],
      },
      {
        label: "Peer feedback",
        data: [28, 48, 40, 19, 96, 94, 1],
      },
      {
        label: "Downward feedback",
        data: [12, 38, 80, 69, 66, 44, 42],
      },
    ],
  },
  radarSkillsSales: {
    labels: ["Sales 1", "Sales 2", "Sales 3"],
    datasets: [
      {
        label: "Self feedback",
        data: [65, 59, 90],
      },
      {
        label: "Peer feedback",
        data: [28, 48, 40],
      },
      {
        label: "Downward feedback",
        data: [12, 38, 80],
      },
    ],
  },
};
