<template>
  <div class="chart-container">
    <canvas ref="pieChart"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  name: "PieChart",
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    legendPosition: {
      type: String,
      default: "right",
    },
    legendAlign: {
      type: String,
      default: "center",
    },
    legendDisplay: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    datasets: "renderChart",
    labels: "renderChart",
  },
  methods: {
    renderChart() {
      if (this.$data._chart) {
        this.$data._chart.destroy();
      }

      this.$data._chart = new Chart(this.$refs.pieChart, {
        type: "pie",
        data: {
          labels: this.labels,
          datasets: this.datasets.map((dataset, index) => ({
            ...dataset,
            backgroundColor: this.getBackgroundColor(index),
            borderColor: this.getBorderColor(index),
            borderWidth: 1,
          })),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,

          legend: {
            display: this.legendDisplay,
            position: this.legendPosition,
            align: this.legendAlign,
          },
        },
      });
    },
    getBackgroundColor() {
      const colors = [
        "#223352",
        "rgba(255, 99, 132, 0.5)",
        "rgba(255, 206, 86, 0.5)",
        "rgba(75, 192, 192, 0.5)",
        "rgba(153, 102, 255, 0.5)",
        "rgba(255, 159, 64, 0.5)",
      ];
      return colors.slice(0, this.labels.length);
    },
    getBorderColor() {
      const colors = [
        "#223352",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ];
      return colors.slice(0, this.labels.length);
    },
  },
};
</script>

<style scoped>
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
