export const FKDD = {
  data: {
    accessi: {
      fields: [
        {
          key: "avatar",
          sortable: false,
        },
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "cognome",
          sortable: true,
        },
        {
          key: "stato",
          sortable: true,
        },
        {
          key: "ruolo",
          sortable: true,
        },
        {
          key: "dataUltimoLogin",
          sortable: true,
        },
        {
          key: "numeroSessioni",
          sortable: true,
        },
        {
          key: "ultimoLog",
          sortable: true,
        },
      ],
      data: [
        {
          id: 1,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Mario",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "Amministratore",
          dataUltimoLogin: "2024-07-01",
          numeroSessioni: 15,
          ultimoLog: "Aggiornato il sistema",
        },
        {
          id: 2,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luigi",
          cognome: "Bianchi",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-02",
          numeroSessioni: 10,
          ultimoLog: "Completata una vendita",
        },
        {
          id: 3,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Anna",
          cognome: "Verdi",
          stato: "Attivo",
          ruolo: "Supervisore",
          dataUltimoLogin: "2024-07-03",
          numeroSessioni: 8,
          ultimoLog: "Verificata la qualità",
        },
        {
          id: 4,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Giovanni",
          cognome: "Neri",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-04",
          numeroSessioni: 12,
          ultimoLog: "Contattato cliente",
        },
        {
          id: 5,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Paolo",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "Amministratore",
          dataUltimoLogin: "2024-07-05",
          numeroSessioni: 20,
          ultimoLog: "Aggiornato il sistema",
        },
        {
          id: 6,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Silvia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "Supervisore",
          dataUltimoLogin: "2024-07-06",
          numeroSessioni: 18,
          ultimoLog: "Revisionato report",
        },
        {
          id: 7,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Elena",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-07",
          numeroSessioni: 6,
          ultimoLog: "Completata una vendita",
        },
        {
          id: 8,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Francesco",
          cognome: "Neri",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-08",
          numeroSessioni: 5,
          ultimoLog: "Aggiornato profilo",
        },
        {
          id: 9,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luca",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "Supervisore",
          dataUltimoLogin: "2024-07-09",
          numeroSessioni: 25,
          ultimoLog: "Verificata la qualità",
        },
        {
          id: 10,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Chiara",
          cognome: "Bianchi",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-10",
          numeroSessioni: 7,
          ultimoLog: "Completata una vendita",
        },
        {
          id: 11,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Marco",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-11",
          numeroSessioni: 9,
          ultimoLog: "Contattato cliente",
        },
        {
          id: 12,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Sara",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "Supervisore",
          dataUltimoLogin: "2024-07-12",
          numeroSessioni: 14,
          ultimoLog: "Revisionato report",
        },
        {
          id: 13,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Davide",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-13",
          numeroSessioni: 3,
          ultimoLog: "Completata una vendita",
        },
        {
          id: 14,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Alessia",
          cognome: "Bianchi",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-14",
          numeroSessioni: 11,
          ultimoLog: "Contattato cliente",
        },
        {
          id: 15,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Federico",
          cognome: "Verdi",
          stato: "Attivo",
          ruolo: "Supervisore",
          dataUltimoLogin: "2024-07-15",
          numeroSessioni: 16,
          ultimoLog: "Revisionato report",
        },
        {
          id: 16,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Mario",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "Amministratore",
          dataUltimoLogin: "2024-07-16",
          numeroSessioni: 19,
          ultimoLog: "Aggiornato il sistema",
        },
        {
          id: 17,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Luigi",
          cognome: "Bianchi",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-17",
          numeroSessioni: 4,
          ultimoLog: "Contattato cliente",
        },
        {
          id: 18,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Anna",
          cognome: "Verdi",
          stato: "Attivo",
          ruolo: "Supervisore",
          dataUltimoLogin: "2024-07-18",
          numeroSessioni: 22,
          ultimoLog: "Verificata la qualità",
        },
        {
          id: 19,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Giovanni",
          cognome: "Neri",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-19",
          numeroSessioni: 8,
          ultimoLog: "Completata una vendita",
        },
        {
          id: 20,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Paolo",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "Amministratore",
          dataUltimoLogin: "2024-07-20",
          numeroSessioni: 17,
          ultimoLog: "Aggiornato il sistema",
        },
        {
          id: 21,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Silvia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "Supervisore",
          dataUltimoLogin: "2024-07-21",
          numeroSessioni: 10,
          ultimoLog: "Revisionato report",
        },
        {
          id: 22,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Elena",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-22",
          numeroSessioni: 6,
          ultimoLog: "Completata una vendita",
        },
        {
          id: 23,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Francesco",
          cognome: "Neri",
          stato: "Non attivo",
          ruolo: "Agente",
          dataUltimoLogin: "2024-07-23",
          numeroSessioni: 5,
          ultimoLog: "Aggiornato profilo",
        },
        {
          id: 24,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luca",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "Supervisore",
          dataUltimoLogin: "2024-07-24",
          numeroSessioni: 25,
          ultimoLog: "Verificata la qualità",
        },
      ],
    },
    formazione: {
      fields: [
        {
          key: "avatar",
          sortable: false,
        },
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "cognome",
          sortable: true,
        },
        {
          key: "stato",
          sortable: true,
        },
        {
          key: "ruolo",
          sortable: true,
        },
        {
          key: "capitoliFatti",
          sortable: true,
        },
        {
          key: "capitoliMancanti",
          sortable: true,
        },
        {
          key: "risposteGiusteSuTotali",
          sortable: true,
        },
        {
          key: "numeroBadgesFormativi",
          sortable: true,
        },
        {
          key: "numeroCertificazioniFormative",
          sortable: true,
        },
      ],
      data: [
        {
          id: 1,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Mario",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "agente",
          capitoliFatti: 5,
          capitoliMancanti: 2,
          risposteGiusteSuTotali: "40/50",
          numeroBadgesFormativi: 3,
          numeroCertificazioniFormative: 1,
        },
        {
          id: 2,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luigi",
          cognome: "Bianchi",
          stato: "Non attivo",
          ruolo: "amministratore",
          capitoliFatti: 3,
          capitoliMancanti: 4,
          risposteGiusteSuTotali: "25/50",
          numeroBadgesFormativi: 2,
          numeroCertificazioniFormative: 0,
        },
        {
          id: 3,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Anna",
          cognome: "Verdi",
          stato: "Attivo",
          ruolo: "backoffice",
          capitoliFatti: 7,
          capitoliMancanti: 0,
          risposteGiusteSuTotali: "45/50",
          numeroBadgesFormativi: 5,
          numeroCertificazioniFormative: 2,
        },
        {
          id: 4,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Giovanni",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          capitoliFatti: 4,
          capitoliMancanti: 3,
          risposteGiusteSuTotali: "35/50",
          numeroBadgesFormativi: 3,
          numeroCertificazioniFormative: 1,
        },
        {
          id: 5,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Paolo",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          capitoliFatti: 6,
          capitoliMancanti: 1,
          risposteGiusteSuTotali: "30/50",
          numeroBadgesFormativi: 4,
          numeroCertificazioniFormative: 1,
        },
        {
          id: 6,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Silvia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          capitoliFatti: 8,
          capitoliMancanti: 0,
          risposteGiusteSuTotali: "48/50",
          numeroBadgesFormativi: 6,
          numeroCertificazioniFormative: 2,
        },
        {
          id: 7,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Elena",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          capitoliFatti: 2,
          capitoliMancanti: 5,
          risposteGiusteSuTotali: "20/50",
          numeroBadgesFormativi: 1,
          numeroCertificazioniFormative: 0,
        },
        {
          id: 8,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Francesco",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          capitoliFatti: 9,
          capitoliMancanti: 1,
          risposteGiusteSuTotali: "50/50",
          numeroBadgesFormativi: 7,
          numeroCertificazioniFormative: 3,
        },
        {
          id: 9,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luca",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "backoffice",
          capitoliFatti: 1,
          capitoliMancanti: 6,
          risposteGiusteSuTotali: "15/50",
          numeroBadgesFormativi: 0,
          numeroCertificazioniFormative: 0,
        },
        {
          id: 10,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Chiara",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "agente",
          capitoliFatti: 5,
          capitoliMancanti: 2,
          risposteGiusteSuTotali: "42/50",
          numeroBadgesFormativi: 3,
          numeroCertificazioniFormative: 1,
        },
        {
          id: 11,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Marco",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "amministratore",
          capitoliFatti: 4,
          capitoliMancanti: 3,
          risposteGiusteSuTotali: "30/50",
          numeroBadgesFormativi: 2,
          numeroCertificazioniFormative: 0,
        },
        {
          id: 12,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Sara",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "backoffice",
          capitoliFatti: 7,
          capitoliMancanti: 0,
          risposteGiusteSuTotali: "47/50",
          numeroBadgesFormativi: 4,
          numeroCertificazioniFormative: 2,
        },
        {
          id: 13,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Davide",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "agente",
          capitoliFatti: 3,
          capitoliMancanti: 4,
          risposteGiusteSuTotali: "22/50",
          numeroBadgesFormativi: 1,
          numeroCertificazioniFormative: 0,
        },
        {
          id: 14,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Alessia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "amministratore",
          capitoliFatti: 8,
          capitoliMancanti: 0,
          risposteGiusteSuTotali: "49/50",
          numeroBadgesFormativi: 6,
          numeroCertificazioniFormative: 3,
        },
        {
          id: 15,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Federico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "backoffice",
          capitoliFatti: 2,
          capitoliMancanti: 5,
          risposteGiusteSuTotali: "18/50",
          numeroBadgesFormativi: 0,
          numeroCertificazioniFormative: 0,
        },
        {
          id: 16,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Giulia",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          capitoliFatti: 6,
          capitoliMancanti: 1,
          risposteGiusteSuTotali: "44/50",
          numeroBadgesFormativi: 5,
          numeroCertificazioniFormative: 2,
        },
        {
          id: 17,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Alberto",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          capitoliFatti: 1,
          capitoliMancanti: 6,
          risposteGiusteSuTotali: "10/50",
          numeroBadgesFormativi: 0,
          numeroCertificazioniFormative: 0,
        },
        {
          id: 18,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Ilaria",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          capitoliFatti: 9,
          capitoliMancanti: 0,
          risposteGiusteSuTotali: "50/50",
          numeroBadgesFormativi: 7,
          numeroCertificazioniFormative: 3,
        },
        {
          id: 19,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Enrico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          capitoliFatti: 4,
          capitoliMancanti: 3,
          risposteGiusteSuTotali: "30/50",
          numeroBadgesFormativi: 2,
          numeroCertificazioniFormative: 1,
        },
        {
          id: 20,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Laura",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          capitoliFatti: 5,
          capitoliMancanti: 2,
          risposteGiusteSuTotali: "40/50",
          numeroBadgesFormativi: 3,
          numeroCertificazioniFormative: 0,
        },
      ],
    },
    tasks: {
      fields: [
        {
          key: "avatar",
          sortable: false,
        },
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "cognome",
          sortable: true,
        },
        {
          key: "stato",
          sortable: true,
        },
        {
          key: "ruolo",
          sortable: true,
        },
        {
          key: "taskEseguite",
          sortable: true,
        },
        {
          key: "taskMancanti",
          sortable: true,
        },
        {
          key: "badgeRelativi",
          sortable: true,
        },
      ],
      data: [
        {
          id: 1,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Mario",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "agente",
          taskEseguite: 15,
          taskMancanti: 3,
          badgeRelativi: 2,
        },
        {
          id: 2,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luigi",
          cognome: "Bianchi",
          stato: "Non attivo",
          ruolo: "amministratore",
          taskEseguite: 10,
          taskMancanti: 5,
          badgeRelativi: 1,
        },
        {
          id: 3,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Anna",
          cognome: "Verdi",
          stato: "Attivo",
          ruolo: "backoffice",
          taskEseguite: 20,
          taskMancanti: 0,
          badgeRelativi: 3,
        },
        {
          id: 4,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Giovanni",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          taskEseguite: 12,
          taskMancanti: 4,
          badgeRelativi: 2,
        },
        {
          id: 5,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Paolo",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          taskEseguite: 18,
          taskMancanti: 2,
          badgeRelativi: 3,
        },
        {
          id: 6,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Silvia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          taskEseguite: 25,
          taskMancanti: 0,
          badgeRelativi: 4,
        },
        {
          id: 7,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Elena",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          taskEseguite: 5,
          taskMancanti: 8,
          badgeRelativi: 1,
        },
        {
          id: 8,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Francesco",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          taskEseguite: 22,
          taskMancanti: 1,
          badgeRelativi: 4,
        },
        {
          id: 9,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luca",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "backoffice",
          taskEseguite: 3,
          taskMancanti: 10,
          badgeRelativi: 0,
        },
        {
          id: 10,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Chiara",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "agente",
          taskEseguite: 17,
          taskMancanti: 3,
          badgeRelativi: 2,
        },
        {
          id: 11,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Marco",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "amministratore",
          taskEseguite: 14,
          taskMancanti: 4,
          badgeRelativi: 2,
        },
        {
          id: 12,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Sara",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "backoffice",
          taskEseguite: 23,
          taskMancanti: 0,
          badgeRelativi: 3,
        },
        {
          id: 13,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Davide",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "agente",
          taskEseguite: 7,
          taskMancanti: 6,
          badgeRelativi: 1,
        },
        {
          id: 14,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Alessia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "amministratore",
          taskEseguite: 21,
          taskMancanti: 2,
          badgeRelativi: 3,
        },
        {
          id: 15,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Federico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "backoffice",
          taskEseguite: 4,
          taskMancanti: 9,
          badgeRelativi: 1,
        },
        {
          id: 16,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Giulia",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          taskEseguite: 16,
          taskMancanti: 3,
          badgeRelativi: 2,
        },
        {
          id: 17,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Alberto",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          taskEseguite: 6,
          taskMancanti: 7,
          badgeRelativi: 1,
        },
        {
          id: 18,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Ilaria",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          taskEseguite: 26,
          taskMancanti: 0,
          badgeRelativi: 4,
        },
        {
          id: 19,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Enrico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          taskEseguite: 11,
          taskMancanti: 5,
          badgeRelativi: 2,
        },
        {
          id: 20,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Laura",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          taskEseguite: 19,
          taskMancanti: 2,
          badgeRelativi: 3,
        },
      ],
    },
    kpis: {
      fields: [
        {
          key: "avatar",
          sortable: false,
        },
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "cognome",
          sortable: true,
        },
        {
          key: "stato",
          sortable: true,
        },
        {
          key: "ruolo",
          sortable: true,
        },
        {
          key: "kpiCompletati",
          sortable: true,
        },
        {
          key: "kpiDaCompletare",
          sortable: true,
        },
        {
          key: "percGeneraleCompletamentoKpi",
          sortable: true,
        },
        {
          key: "badgesRelativi",
          sortable: true,
        },
      ],
      data: [
        {
          id: 1,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Mario",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "agente",
          kpiCompletati: 12,
          kpiDaCompletare: 3,
          percGeneraleCompletamentoKpi: 80,
          badgesRelativi: 2,
        },
        {
          id: 2,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luigi",
          cognome: "Bianchi",
          stato: "Non attivo",
          ruolo: "amministratore",
          kpiCompletati: 10,
          kpiDaCompletare: 5,
          percGeneraleCompletamentoKpi: 67,
          badgesRelativi: 1,
        },
        {
          id: 3,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Anna",
          cognome: "Verdi",
          stato: "Attivo",
          ruolo: "backoffice",
          kpiCompletati: 20,
          kpiDaCompletare: 0,
          percGeneraleCompletamentoKpi: 100,
          badgesRelativi: 3,
        },
        {
          id: 4,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Giovanni",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          kpiCompletati: 15,
          kpiDaCompletare: 4,
          percGeneraleCompletamentoKpi: 79,
          badgesRelativi: 2,
        },
        {
          id: 5,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Paolo",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          kpiCompletati: 18,
          kpiDaCompletare: 2,
          percGeneraleCompletamentoKpi: 90,
          badgesRelativi: 3,
        },
        {
          id: 6,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Silvia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          kpiCompletati: 25,
          kpiDaCompletare: 0,
          percGeneraleCompletamentoKpi: 100,
          badgesRelativi: 4,
        },
        {
          id: 7,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Elena",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          kpiCompletati: 5,
          kpiDaCompletare: 8,
          percGeneraleCompletamentoKpi: 38,
          badgesRelativi: 1,
        },
        {
          id: 8,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Francesco",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          kpiCompletati: 22,
          kpiDaCompletare: 1,
          percGeneraleCompletamentoKpi: 96,
          badgesRelativi: 4,
        },
        {
          id: 9,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luca",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "backoffice",
          kpiCompletati: 3,
          kpiDaCompletare: 10,
          percGeneraleCompletamentoKpi: 23,
          badgesRelativi: 0,
        },
        {
          id: 10,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Chiara",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "agente",
          kpiCompletati: 17,
          kpiDaCompletare: 3,
          percGeneraleCompletamentoKpi: 85,
          badgesRelativi: 2,
        },
        {
          id: 11,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Marco",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "amministratore",
          kpiCompletati: 14,
          kpiDaCompletare: 4,
          percGeneraleCompletamentoKpi: 78,
          badgesRelativi: 2,
        },
        {
          id: 12,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Sara",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "backoffice",
          kpiCompletati: 23,
          kpiDaCompletare: 0,
          percGeneraleCompletamentoKpi: 100,
          badgesRelativi: 3,
        },
        {
          id: 13,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Davide",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "agente",
          kpiCompletati: 7,
          kpiDaCompletare: 6,
          percGeneraleCompletamentoKpi: 54,
          badgesRelativi: 1,
        },
        {
          id: 14,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Alessia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "amministratore",
          kpiCompletati: 21,
          kpiDaCompletare: 2,
          percGeneraleCompletamentoKpi: 91,
          badgesRelativi: 3,
        },
        {
          id: 15,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Federico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "backoffice",
          kpiCompletati: 4,
          kpiDaCompletare: 9,
          percGeneraleCompletamentoKpi: 31,
          badgesRelativi: 1,
        },
        {
          id: 16,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Giulia",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          kpiCompletati: 16,
          kpiDaCompletare: 3,
          percGeneraleCompletamentoKpi: 84,
          badgesRelativi: 2,
        },
        {
          id: 17,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Alberto",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          kpiCompletati: 6,
          kpiDaCompletare: 7,
          percGeneraleCompletamentoKpi: 46,
          badgesRelativi: 1,
        },
        {
          id: 18,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Ilaria",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          kpiCompletati: 26,
          kpiDaCompletare: 0,
          percGeneraleCompletamentoKpi: 100,
          badgesRelativi: 4,
        },
        {
          id: 19,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Enrico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          kpiCompletati: 11,
          kpiDaCompletare: 5,
          percGeneraleCompletamentoKpi: 69,
          badgesRelativi: 2,
        },
        {
          id: 20,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Laura",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          kpiCompletati: 19,
          kpiDaCompletare: 2,
          percGeneraleCompletamentoKpi: 91,
          badgesRelativi: 3,
        },
      ],
    },

    socials: {
      fields: [
        {
          key: "avatar",
          sortable: false,
        },
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "cognome",
          sortable: true,
        },
        {
          key: "stato",
          sortable: true,
        },
        {
          key: "ruolo",
          sortable: true,
        },
        {
          key: "numeroCondivisioni",
          sortable: true,
        },
        {
          key: "tassoConversioni",
          sortable: true,
        },
        {
          key: "badgeRelativi",
          sortable: true,
        },
        {
          key: "socialPiuUtilizzato",
          sortable: true,
        },
      ],
      data: [
        {
          id: 1,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Mario",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "agente",
          numeroCondivisioni: 120,
          tassoConversioni: 15,
          badgeRelativi: 2,
          socialPiuUtilizzato: "Facebook",
        },
        {
          id: 2,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luigi",
          cognome: "Bianchi",
          stato: "Non attivo",
          ruolo: "amministratore",
          numeroCondivisioni: 80,
          tassoConversioni: 10,
          badgeRelativi: 1,
          socialPiuUtilizzato: "Twitter",
        },
        {
          id: 3,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Anna",
          cognome: "Verdi",
          stato: "Attivo",
          ruolo: "backoffice",
          numeroCondivisioni: 200,
          tassoConversioni: 25,
          badgeRelativi: 3,
          socialPiuUtilizzato: "LinkedIn",
        },
        {
          id: 4,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Giovanni",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          numeroCondivisioni: 150,
          tassoConversioni: 20,
          badgeRelativi: 2,
          socialPiuUtilizzato: "Instagram",
        },
        {
          id: 5,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Paolo",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          numeroCondivisioni: 180,
          tassoConversioni: 22,
          badgeRelativi: 3,
          socialPiuUtilizzato: "Twitter",
        },
        {
          id: 6,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Silvia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          numeroCondivisioni: 250,
          tassoConversioni: 30,
          badgeRelativi: 4,
          socialPiuUtilizzato: "Facebook",
        },
        {
          id: 7,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Elena",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          numeroCondivisioni: 50,
          tassoConversioni: 8,
          badgeRelativi: 1,
          socialPiuUtilizzato: "Instagram",
        },
        {
          id: 8,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Francesco",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          numeroCondivisioni: 220,
          tassoConversioni: 27,
          badgeRelativi: 4,
          socialPiuUtilizzato: "LinkedIn",
        },
        {
          id: 9,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luca",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "backoffice",
          numeroCondivisioni: 30,
          tassoConversioni: 5,
          badgeRelativi: 0,
          socialPiuUtilizzato: "Facebook",
        },
        {
          id: 10,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Chiara",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "agente",
          numeroCondivisioni: 170,
          tassoConversioni: 21,
          badgeRelativi: 2,
          socialPiuUtilizzato: "Twitter",
        },
        {
          id: 11,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Marco",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "amministratore",
          numeroCondivisioni: 140,
          tassoConversioni: 18,
          badgeRelativi: 2,
          socialPiuUtilizzato: "LinkedIn",
        },
        {
          id: 12,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Sara",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "backoffice",
          numeroCondivisioni: 230,
          tassoConversioni: 28,
          badgeRelativi: 3,
          socialPiuUtilizzato: "Instagram",
        },
        {
          id: 13,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Davide",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "agente",
          numeroCondivisioni: 70,
          tassoConversioni: 12,
          badgeRelativi: 1,
          socialPiuUtilizzato: "Facebook",
        },
        {
          id: 14,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Alessia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "amministratore",
          numeroCondivisioni: 210,
          tassoConversioni: 26,
          badgeRelativi: 3,
          socialPiuUtilizzato: "Twitter",
        },
        {
          id: 15,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Federico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "backoffice",
          numeroCondivisioni: 40,
          tassoConversioni: 7,
          badgeRelativi: 1,
          socialPiuUtilizzato: "LinkedIn",
        },
        {
          id: 16,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Giulia",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          numeroCondivisioni: 160,
          tassoConversioni: 19,
          badgeRelativi: 2,
          socialPiuUtilizzato: "Instagram",
        },
        {
          id: 17,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Alberto",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          numeroCondivisioni: 60,
          tassoConversioni: 9,
          badgeRelativi: 1,
          socialPiuUtilizzato: "Facebook",
        },
        {
          id: 18,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Ilaria",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          numeroCondivisioni: 260,
          tassoConversioni: 32,
          badgeRelativi: 4,
          socialPiuUtilizzato: "LinkedIn",
        },
        {
          id: 19,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Enrico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          numeroCondivisioni: 110,
          tassoConversioni: 14,
          badgeRelativi: 2,
          socialPiuUtilizzato: "Twitter",
        },
        {
          id: 20,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Laura",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          numeroCondivisioni: 190,
          tassoConversioni: 24,
          badgeRelativi: 3,
          socialPiuUtilizzato: "Instagram",
        },
      ],
    },

    badges: {
      fields: [
        {
          key: "avatar",
          sortable: false,
        },
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "cognome",
          sortable: true,
        },
        {
          key: "stato",
          sortable: true,
        },
        {
          key: "ruolo",
          sortable: true,
        },
        {
          key: "badgesConquistati",
          sortable: true,
        },
        {
          key: "badgeRimanenti",
          sortable: true,
        },
      ],
      data: [
        {
          id: 1,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Mario",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "agente",
          badgesConquistati: 3,
          badgeRimanenti: 1,
        },
        {
          id: 2,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luigi",
          cognome: "Bianchi",
          stato: "Non attivo",
          ruolo: "amministratore",
          badgesConquistati: 2,
          badgeRimanenti: 2,
        },
        {
          id: 3,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Anna",
          cognome: "Verdi",
          stato: "Attivo",
          ruolo: "backoffice",
          badgesConquistati: 5,
          badgeRimanenti: 0,
        },
        {
          id: 4,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Giovanni",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          badgesConquistati: 4,
          badgeRimanenti: 1,
        },
        {
          id: 5,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Paolo",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          badgesConquistati: 3,
          badgeRimanenti: 1,
        },
        {
          id: 6,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Silvia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          badgesConquistati: 6,
          badgeRimanenti: 0,
        },
        {
          id: 7,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Elena",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          badgesConquistati: 1,
          badgeRimanenti: 3,
        },
        {
          id: 8,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Francesco",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          badgesConquistati: 5,
          badgeRimanenti: 0,
        },
        {
          id: 9,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luca",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "backoffice",
          badgesConquistati: 0,
          badgeRimanenti: 4,
        },
        {
          id: 10,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Chiara",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "agente",
          badgesConquistati: 4,
          badgeRimanenti: 1,
        },
        {
          id: 11,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Marco",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "amministratore",
          badgesConquistati: 2,
          badgeRimanenti: 2,
        },
        {
          id: 12,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Sara",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "backoffice",
          badgesConquistati: 5,
          badgeRimanenti: 0,
        },
        {
          id: 13,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Davide",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "agente",
          badgesConquistati: 1,
          badgeRimanenti: 3,
        },
        {
          id: 14,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Alessia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "amministratore",
          badgesConquistati: 5,
          badgeRimanenti: 0,
        },
        {
          id: 15,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Federico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "backoffice",
          badgesConquistati: 1,
          badgeRimanenti: 3,
        },
        {
          id: 16,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Giulia",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          badgesConquistati: 4,
          badgeRimanenti: 1,
        },
        {
          id: 17,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Alberto",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          badgesConquistati: 2,
          badgeRimanenti: 2,
        },
        {
          id: 18,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Ilaria",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          badgesConquistati: 6,
          badgeRimanenti: 0,
        },
        {
          id: 19,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Enrico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          badgesConquistati: 3,
          badgeRimanenti: 1,
        },
        {
          id: 20,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Laura",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          badgesConquistati: 4,
          badgeRimanenti: 1,
        },
      ],
    },

    awards: {
      fields: [
        {
          key: "avatar",
          sortable: false,
        },
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "cognome",
          sortable: true,
        },
        {
          key: "stato",
          sortable: true,
        },
        {
          key: "ruolo",
          sortable: true,
        },
        {
          key: "awardsConquistati",
          sortable: true,
        },
        {
          key: "awardsRimanenti",
          sortable: true,
        },
      ],
      data: [
        {
          id: 1,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Mario",
          cognome: "Rossi",
          stato: "Attivo",
          ruolo: "agente",
          awardsConquistati: 4,
          awardsRimanenti: 2,
        },
        {
          id: 2,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luigi",
          cognome: "Bianchi",
          stato: "Non attivo",
          ruolo: "amministratore",
          awardsConquistati: 2,
          awardsRimanenti: 3,
        },
        {
          id: 3,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Anna",
          cognome: "Verdi",
          stato: "Attivo",
          ruolo: "backoffice",
          awardsConquistati: 5,
          awardsRimanenti: 1,
        },
        {
          id: 4,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Giovanni",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          awardsConquistati: 3,
          awardsRimanenti: 4,
        },
        {
          id: 5,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Paolo",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          awardsConquistati: 1,
          awardsRimanenti: 5,
        },
        {
          id: 6,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Silvia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          awardsConquistati: 6,
          awardsRimanenti: 0,
        },
        {
          id: 7,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Elena",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          awardsConquistati: 2,
          awardsRimanenti: 3,
        },
        {
          id: 8,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Francesco",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          awardsConquistati: 5,
          awardsRimanenti: 1,
        },
        {
          id: 9,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Luca",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "backoffice",
          awardsConquistati: 1,
          awardsRimanenti: 4,
        },
        {
          id: 10,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Chiara",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "agente",
          awardsConquistati: 4,
          awardsRimanenti: 3,
        },
        {
          id: 11,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Marco",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "amministratore",
          awardsConquistati: 3,
          awardsRimanenti: 2,
        },
        {
          id: 12,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Sara",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "backoffice",
          awardsConquistati: 5,
          awardsRimanenti: 1,
        },
        {
          id: 13,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Davide",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "agente",
          awardsConquistati: 0,
          awardsRimanenti: 6,
        },
        {
          id: 14,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Alessia",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "amministratore",
          awardsConquistati: 4,
          awardsRimanenti: 2,
        },
        {
          id: 15,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Federico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "backoffice",
          awardsConquistati: 3,
          awardsRimanenti: 3,
        },
        {
          id: 16,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Giulia",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "agente",
          awardsConquistati: 4,
          awardsRimanenti: 2,
        },
        {
          id: 17,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Alberto",
          cognome: "Rossi",
          stato: "Non attivo",
          ruolo: "amministratore",
          awardsConquistati: 2,
          awardsRimanenti: 4,
        },
        {
          id: 18,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Ilaria",
          cognome: "Bianchi",
          stato: "Attivo",
          ruolo: "backoffice",
          awardsConquistati: 6,
          awardsRimanenti: 1,
        },
        {
          id: 19,
          avatar: "https://avatar.iran.liara.run/public/boy",
          nome: "Enrico",
          cognome: "Verdi",
          stato: "Non attivo",
          ruolo: "agente",
          awardsConquistati: 3,
          awardsRimanenti: 3,
        },
        {
          id: 20,
          avatar: "https://avatar.iran.liara.run/public/girl",
          nome: "Laura",
          cognome: "Neri",
          stato: "Attivo",
          ruolo: "amministratore",
          awardsConquistati: 5,
          awardsRimanenti: 2,
        },
      ],
    },
  },
};
