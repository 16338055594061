<script>
import AxiosService from "@/axiosServices/AxiosService";
import IndicatorDisplay2 from "../components/DashBoard/IndicatorDisplay2.vue";
import LineGraph from "../components/DashBoard/LineGraph.vue";
import BarChart from "../components/DashBoard/BarChart.vue";
import BubbleChart from "../components/DashBoard/BubbleChart.vue";
import RadarChart from "../components/DashBoard/RadarChart";
import CombinedChart from "../components/DashBoard/CombinedChart.vue";
import StackedBarChart from "../components/DashBoard/StackedBarChart.vue";
import PieChart from "../components/DashBoard/PieChart.vue";
import HorizontalBars from "../components/DashBoard/HorizontalBars.vue";

import { stats } from "@/utils/fakeData.js";
import { FKDD } from "@/utils/fakeDashData.js";
export default {
  name: "DashboardStats",
  components: {
    IndicatorDisplay2,
    LineGraph,
    BarChart,
    BubbleChart,
    RadarChart,
    CombinedChart,
    StackedBarChart,
    PieChart,
    HorizontalBars,
  },
  service: null,
  created() {
    this.service = new AxiosService("Dashboard");
    this.drawMarkersMap();
  },
  data() {
    return {
      date1: null,
      date2: null,
      stats,
      loadingModal: false,
      tempModalData: {},
      selectedSkill: "Total",
      statsMaps: [
        ["Città", "Accessi", "Learning"],
        ["Torino", 15000, 12000],
        ["Solofra", 22000, 18000],
        ["Siderno", 8000, 6000],
        ["Palermo", 12000, 11000],
        ["Milano", 35000, 30000],
        ["Lecce", 7000, 5000],
        ["Cosenza", 11000, 9500],
        ["Catania", 13000, 11500],
        ["Bologna", 14000, 12000],
        ["Bari", 20000, 17000],
        ["Avellino", 10000, 9000],
        ["Pomigliano d'Arco", 8500, 4000],
        ["Roma", 50000, 45000],
        ["Napoli", 18000, 15000],
        ["Reggio di Calabria", 7000, 6000],
        ["Salerno", 5000, 4500],
        ["Verona", 9000, 8000],
        ["Milano (2)", 36000, 32000],
      ],
      googleStats: null,
      dataModalDetails: [],
      fieldsModalDetails: [],
      firstColumn: "Accessi",
      secondColumn: "Learning",
      columns: [
        "Accessi",
        "Learning",
        "Tasks",
        "KPIs",
        "Socials",
        "Badges",
        "Awards",
      ],
      socialIcon: {
        Facebook: "b-icon-facebook",
        Twitter: "b-icon-twitter",
        Instagram: "b-icon-instagram",
        YouTube: "b-icon-youtube",
        LinkedIn: "b-icon-linkedin",
        Tumbler: "b-icon-tumbler",
      },

      filtri: [
        {
          label: "Azienda",
          value: "1",
          options: [
            { value: "1", text: "Martech Revolution" },
            { value: "2", text: "Whappy" },
          ],
        },
        {
          label: "Area",
          value: "1",
          options: [
            { value: "1", text: "IT" },
            { value: "2", text: "Marketing" },
            { value: "3", text: "Sales" },
            { value: "4", text: "HR" },
            { value: "5", text: "Finance" },
            { value: "6", text: "Other" },
          ],
        },
        {
          label: "Settore",
          value: "1",
          options: [
            { value: "1", text: "Software" },
            { value: "2", text: "Web" },
            { value: "3", text: "Mobile" },
            { value: "4", text: "Desktop" },
          ],
        },
        {
          label: "Ruolo",
          value: "1",
          options: [
            { value: "1", text: "Manager" },
            { value: "2", text: "Developer" },
            { value: "3", text: "Designer" },
            { value: "4", text: "Analyst" },
            { value: "5", text: "Tester" },
          ],
        },
      ],
    };
  },
  methods: {
    camelToNormalCase(str) {
      return str
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .toLowerCase()
        .replace(/\b\w/g, (match) => match.toUpperCase());
    },
    handleColumnChange() {
      // Update column headers based on selected columns
      this.statsMaps[0][1] = this.firstColumn;
      this.statsMaps[0][2] = this.secondColumn;

      // Update numeric values dynamically (dummy values in this example)
      this.statsMaps = this.statsMaps.map((row, index) => {
        if (index > 0) {
          // Skip header row
          return [
            row[0],
            this.getRandomValue(), // Update this with your actual logic
            this.getRandomValue(), // Update this with your actual logic
          ];
        }
        return row; // Return header row unchanged
      });

      this.drawMarkersMap();
    },
    getRandomValue() {
      // Function to generate random values for demonstration; replace with actual logic
      return Math.floor(Math.random() * 30000) + 5000;
    },
    gFunction() {
      let gData = google.visualization.arrayToDataTable(this.statsMaps);

      let options = {
        region: "IT",
        displayMode: "markers",
        colorAxis: { colors: ["#92AAC7", "#1D3461"] },
        sizeAxis: {
          minValue: 0,
          maxValue: Math.max(...this.statsMaps.map((x) => x[2])),
        },
      };

      let chart = new google.visualization.GeoChart(
        document.getElementById("google_chart_bubble_map")
      );

      chart.draw(gData, options);
    },
    drawMarkersMap() {
      google.charts.load("current", {
        packages: ["geochart"],
        mapsApiKey: "AIzaSyBjSBLe29M5dLVw1s8QoeZ-VmNaeodJBV0",
      });
      google.charts.setOnLoadCallback(() => this.gFunction());
    },
    getStats() {
      this.handleColumnChange();
    },

    showDetails(data) {
      this.dataModalDetails.splice(0);
      this.fieldsModalDetails.splice(0);
      this.dataModalDetails = [...FKDD.data[data].data];
      this.fieldsModalDetails = [...FKDD.data[data].fields];
      this.$bvModal.show("dataDetails");
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <h1>Dashboard</h1>

    <div>
      <b-row class="mb-5">
        <b-col cols="12">
          <div class="filtri-container shadow-sm">
            <h2>Filtri</h2>
            <b-row class="mb-4">
              <b-col v-for="(filter, i) in filtri" :key="i" cols="12" md="3">
                <label>{{ filter.label }}</label>
                <b-form-select
                  v-model="filter.value"
                  :options="filter.options"
                ></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="3">
                <label for="datepicker1">Da</label>
                <b-form-datepicker
                  id="datepicker1"
                  v-model="date1"
                  class="mb-2"
                  locale="it"
                ></b-form-datepicker>
              </b-col>

              <b-col cols="12" md="3">
                <label for="datepicker2">A</label>
                <b-form-datepicker
                  id="datepicker2"
                  v-model="date2"
                  class="mb-2"
                  locale="it"
                ></b-form-datepicker>
              </b-col>
              <b-col cols="12" md="6">
                <div
                  class="w-100 h-100 d-flex justify-content-end align-items-end"
                >
                  <b-button @click="getStats()">
                    <b-icon icon="search" class="mr-2" />
                    Filtra</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <!-- ACCESSI -->
          <b-card
            style="background-color: #f7f9ff; border: 5px solid #afa600"
            class="my-4 rounded-xl shadow-sm shadow-lg"
          >
            <h2
              class="gfont-400"
              :style="{
                color: '#706d1f',
                fontSize: '40px',
              }"
            >
              Accesses and Usage
              <b-button
                @click="showDetails('accessi')"
                variant="link"
                class="float-right"
                >Details</b-button
              >
            </h2>

            <b-row class="my-4 py-4">
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0 shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Active Users',
                      value: 70,
                      tot: 73,
                      type: 'perc',
                      unit: 'utenti',
                      decimals: 0,
                      description:
                        'In questa fase, ci sono 70 utenti attivi nel sistema, in totale 73.',
                    }"
                  />
                  <div class="flex justify-content-center">
                    <span
                      class="gfont-400 mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      70 / 73 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0 shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'New Users %',
                      value: 2,
                      tot: 73,
                      type: 'perc',
                      unit: 'utenti',
                      decimals: 0,
                      description:
                        'Questo indicatore misura il numero di nuovi utenti rispetto al totale degli utenti. In questa fase, ci sono 2 nuovi utenti su 73.',
                    }"
                  />
                  <div class="flex justify-content-center">
                    <span
                      class="gfont-400 mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      2 / 73 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0 shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'New Active Users',
                      value: 1,
                      tot: 2,
                      type: 'perc',
                      unit: 'utenti',
                      decimals: 0,
                      description:
                        'Questo indicatore misura il numero di nuovi utenti attivi rispetto al totale dei nuovi utenti. In questa fase, ci sono 1 nuovo utente attivo su 2.',
                    }"
                  />
                  <div class="flex justify-content-center">
                    <span
                      class="gfont-400 mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      1 / 2 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0 shadow-sm"
                  style="height: 265px;"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Average Sessions per Active User',
                      value: 2,
                      type: 'text',
                      description:
                        'Indicatore che mostra la media di completamento Kpi per utente.',
                    }"
                    class="mt-4"
                    style="height: 265px;"
                  />
                </b-card>
              </b-col>

              <b-col cols="2" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm shadow-sm"
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Peak times</h4>
                  </div>
                  <BarChart
                    :datasets="stats.sessioniAttive"
                    :labels="['Morning', 'Afternoon', 'Evening', 'Night']"
                    class="h-75"
                  />
                </b-card>
              </b-col>

              <b-col cols="2" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm shadow-sm"
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Roles</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['User', 'Backoffice', 'Admin']"
                    class="h-75"
                    :legendPosition="'bottom'"
                  />
                </b-card>
              </b-col>

              <b-col cols="6" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm shadow-sm"
                  style="height: 265px;"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Logins</h4>
                  </div>
                  <LineGraph
                    :datasets="stats.datasets"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>

              <b-col cols="6" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm shadow-sm"
                  style="height: 265px;"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Sessions</h4>
                  </div>
                  <BarChart
                    :datasets="stats.sessioniAttive"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12">
          <!-- FORMAZIONE -->
          <b-card
            style="background-color: #f7f9ff; border: 5px solid #b95a02;"
            class="my-4 rounded-xl  shadow-lg"
          >
            <h2
              class="gfont-400"
              :style="{
                color: '#68121e',

                fontSize: '40px',
              }"
            >
              Learning
              <b-button
                @click="showDetails('formazione')"
                variant="link"
                class="float-right"
                >Details</b-button
              >
            </h2>

            <b-row class="my-4 py-4" align-v="stretch">
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Users doing training',
                      value: 70,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicazione sulla relazione utenti formazione / utenti attivi, gli utenti formazione sono i nuovi utenti che hanno completato il processo di formazione. Quelli attivi sono quelli che hanno completato il processo di formazione e hanno effettuato almeno una sessione di formazione.',
                    }"
                  />
                  <div class="flex justify-content-center">
                    <span
                      class="gfont-400 mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      70 / 73 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Users who completed the quiz',
                      value: 50,
                      tot: 70,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicazione sulla relazione utenti che hanno completato il quiz rispetto agli utenti che hanno fatto il training. Questo mostra quanti degli utenti che hanno completato il training hanno anche completato il quiz.',
                    }"
                  />
                  <div class="flex justify-content-center">
                    <span
                      class="gfont-400 mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      50 / 70 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Total right answers',
                      value: 123,
                      tot: 730,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicazione sulla percentuale di risposte corrette nei questionari di formazione e onboarding di Whappy.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class="gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      123 / 730 answers
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Completed chapters',
                      value: 123,
                      tot: 730,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicazione sulla percentuale di risposte corrette nei questionari di formazione e onboarding di Whappy.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class="gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      123 / 730 <br />
                      chapters
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% Badges gained',
                      value: 16,
                      tot: 23,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Questo numero mostra il numero di capitoli completati sul totale di 23.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      16 / 23 <br />badges
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% Certifications gained',
                      value: 8,
                      tot: 24,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Questo numero mostra il numero di badge ottenuti in questa sessione di formazione.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      8 / 34 <br />
                      certification
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col cols="2" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm h-100"
                  style=" min-height: 265px ;height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Peak times</h4>
                  </div>
                  <BarChart
                    :datasets="stats.sessioniAttive"
                    :labels="['Morning', 'Afternoon', 'Evening', 'Night']"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="3" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 365px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Available chapters by Type</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="[
                      'Accademy',
                      'Continous Improvement ',
                      'Sprint formativi',
                    ]"
                    legend-position="bottom"
                    legend-align="start"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="3" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 365px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Completed training by Type</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="[
                      'Accademy',
                      'Continous Improvement ',
                      'Sprint formativi',
                    ]"
                    legend-position="bottom"
                    legend-align="start"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="4" class="mb-5">
                <b-card class="rounded-xl shadow-sm " style="height: 100%; ">
                  <h4>Completion Rate of Training Chapters by Type</h4>
                  <div
                    class="h-100 flex flex-column "
                    style="justify-content: space-around"
                  >
                    <HorizontalBars
                      label="Accademy"
                      :value="23"
                      :tot="40"
                      description="Risposte corrette su risposte totali, per l'accademy. 23 risposte corrette su 40 risposte totali"
                    />

                    <HorizontalBars
                      label="Continuous Improvement"
                      :value="7"
                      :tot="9"
                      description="Risposte corrette su risposte totali, per Continuous Improvement. 7 risposte corrette su 9 risposte totali"
                    />

                    <HorizontalBars
                      label="Sprint Formativi"
                      :value="100"
                      :tot="300"
                      description="Risposte corrette su risposte totali, per Sprint Formativi. 100 risposte corrette su 300 risposte totali"
                    />
                  </div>
                </b-card>
              </b-col>
              <b-col cols="2" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Roles</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['User', 'Backoffice', 'Admin']"
                    class="h-75"
                    legend-position="bottom"
                    legend-align="start"
                  />
                </b-card>
              </b-col>

              <b-col cols="5" class="mb-5">
                <b-card class="h-100 rounded-xl shadow-sm">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>
                      Completed chapters
                    </h4>
                  </div>
                  <BarChart
                    :datasets="stats.mediaCapitoliCompletatiPerUtente"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>

              <b-col cols="5" class="mb-5">
                <b-card
                  class="h-100 rounded-xl shadow-sm"
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Correct answers</h4>
                  </div>
                  <CombinedChart
                    :datasets="stats.risposteGiusteSuTotali"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12">
          <!-- TASKS -->
          <b-card
            style="background-color: #f7f9ff; border: 5px solid #2d007a;"
            class="my-4 rounded-xl shadow-lg"
          >
            <h2
              class="gfont-400"
              :style="{
                color: '#2d007a',

                fontSize: '40px',
              }"
            >
              Task
              <b-button
                @click="showDetails('tasks')"
                variant="link"
                class="float-right"
                >Details</b-button
              >
            </h2>
            <b-row class="my-4 py-4">
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Users doing tasks',
                      value: 70,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Gli utenti task sono i membri che hanno completato almeno una task. Gli utenti attivi sono i membri che hanno effettuato almeno un login.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      70 / 73 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% completed tasks',
                      value: 43,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicatore che mostra quanti utenti hanno effettuato almeno una task.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400   mt-4"
                      style="font-size: 24px; text-align: center;"
                    >
                      43 / 73 <br />
                      tasks
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0 shadow-sm"
                  style="height: 265px;"
                >
                  <indicator-display2
                    :indicator="{
                      title: 'Average Task per Active User',
                      value: 12,
                      type: 'text',
                      description:
                        'Indicatore che mostra la media di task per utente attivo',
                    }"
                    class="mt-4"
                    style="height: 265px;"
                  ></indicator-display2>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0"
                  style="height: 265px;"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% badges gained',
                      value: 38,
                      tot: 43,
                      type: 'perc',
                      unit: '%',
                      decimals: 0,
                      description:
                        'Indicatore che mostra quante task sono stati completate da ogni utente.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center "
                    >
                      38 / 43 <br />
                      badges
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0"
                  style="height: 265px;"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% certifications gained',
                      value: 38,
                      tot: 43,
                      type: 'perc',
                      unit: '%',
                      decimals: 0,
                      description:
                        'Indicatore che mostra quante task sono stati completate da ogni utente.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center "
                    >
                      38 / 43 <br />
                      certifications
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col cols="2" class=" mb-5">
                <b-card class="rounded-xl shadow-sm h-100">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Roles</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['User', 'Backoffice', 'Admin']"
                    class="h-75"
                    legend-position="bottom"
                    legend-align="start"
                  />
                </b-card>
              </b-col>

              <b-col cols="3" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 365px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Pending tasks</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['Planogram', 'Checking list', 'Order insertion']"
                    legend-position="bottom"
                    legend-align="start"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="3" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 365px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Completed tasks</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['Planogram', 'Checking list', 'Order insertion']"
                    legend-position="bottom"
                    legend-align="start"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="6" class="mb-5">
                <b-card class="rounded-xl shadow-sm " style="height: 100%; ">
                  <h4>Completion Rate of Tasks by Type</h4>

                  <div
                    class="h-100 flex flex-column "
                    style="justify-content: space-around"
                  >
                    <HorizontalBars
                      label="Planogram"
                      :value="23"
                      :tot="40"
                      description="Risposte corrette su risposte totali, per il Planogram. 23 item correttamente inseriti su 40 item totali"
                    />

                    <HorizontalBars
                      label="Checking List"
                      :value="7"
                      :tot="9"
                      description="Risposte corrette su risposte totali, per la Checking List. 7 task completate su 9 risposte totali"
                    />

                    <HorizontalBars
                      label="Order Insertion"
                      :value="100"
                      :tot="300"
                      description="Risposte corrette su risposte totali, per l'inserimento degli ordini. 100 ordini inseriti su 300 totali"
                    />
                  </div>
                </b-card>
              </b-col>
              <b-col cols="2" class="mb-5">
                <b-card class="rounded-xl shadow-sm " style="height: 265px;">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Peak times</h4>
                  </div>
                  <BarChart
                    :datasets="stats.sessioniAttive"
                    :labels="['Morning', 'Afternoon', 'Evening', 'Night']"
                    class="h-75"
                  />
                </b-card>
              </b-col>

              <b-col cols="5" class="mb-5">
                <b-card
                  class="h-100 rounded-xl shadow-sm"
                  style="height: 265px !important;"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Completed tasks</h4>
                  </div>
                  <BarChart
                    :datasets="stats.mediaTaskEseguitePerUtente"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>

              <b-col cols="5" class="mb-5">
                <b-card class="h-100 rounded-xl shadow-sm">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Completed tasks over assigned</h4>
                  </div>
                  <CombinedChart
                    :datasets="stats.taskCompletateSuAssegnate"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card
            style="background-color: #f7f9ff; border: 5px solid #285053"
            class="my-4 rounded-xl shadow-lg"
          >
            <h2
              class="gfont-400"
              :style="{
                color: '#285053',
                fontSize: '40px',
              }"
            >
              KPI
              <b-button
                @click="showDetails('kpis')"
                variant="link"
                class="float-right"
                >Details</b-button
              >
            </h2>
            <b-row class="my-4 py-4" align-v="stretch">
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Users doing Kpis',
                      value: 53,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicatore che mostra il % di utenti attivi che hanno completato KPI.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center ;"
                    >
                      53 / 73 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% achieved Kpis',
                      value: 63,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicatore che mostra il % di miglioramento Kpi migliori in questo periodo.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      63 / 73 <br />
                      kpis
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0 shadow-sm"
                  style="height: 265px;"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Avarage achieved kpis per user',
                      value: 13,
                      type: 'text',
                      description:
                        'Indicatore che mostra la media di completamento Kpi per utente.',
                    }"
                    class="mt-4"
                    style="height: 265px;"
                  />
                </b-card>
              </b-col>

              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0"
                  style="height: 265px;"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% badges gained',
                      value: 38,
                      tot: 43,
                      type: 'perc',
                      unit: '%',
                      decimals: 0,
                      description:
                        'Indicatore che mostra quante task sono stati completate da ogni utente.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center "
                    >
                      38 / 43 <br />
                      badges
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0"
                  style="height: 265px;"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% certifications gained',
                      value: 38,
                      tot: 43,
                      type: 'perc',
                      unit: '%',
                      decimals: 0,
                      description:
                        'Indicatore che mostra quante task sono stati completate da ogni utente.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center "
                    >
                      38 / 43 <br />
                      certifications
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col cols="2" class=" mb-5">
                <b-card class="rounded-xl shadow-sm h-100">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Roles</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['User', 'Backoffice', 'Admin']"
                    legend-position="bottom"
                    legend-align="start"
                    class="h-75"
                  />
                </b-card>
              </b-col>

              <b-col cols="2" class="mb-5">
                <b-card class="rounded-xl shadow-sm " style="height: 265px;">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Peak times</h4>
                  </div>
                  <BarChart
                    :datasets="stats.sessioniAttive"
                    :labels="['Morning', 'Afternoon', 'Evening', 'Night']"
                    class="h-75"
                  />
                </b-card>
              </b-col>

              <b-col cols="2" class="mb-5">
                <b-card class="rounded-xl shadow-sm " style="height: 265px;">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Monitored kpis by type</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['Sales', 'Marketing', 'Support']"
                    class="h-75"
                    legend-position="bottom"
                    legend-align="start"
                  />
                </b-card>
              </b-col>
              <b-col cols="2" class="mb-5">
                <b-card class="rounded-xl shadow-sm " style="height: 265px;">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Completed kpis by type</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['Sales', 'Marketing', 'Support']"
                    class="h-75"
                    legend-position="bottom"
                    legend-align="start"
                  />
                </b-card>
              </b-col>
              <b-col cols="2" class="mb-5">
                <b-card class="rounded-xl shadow-sm " style="height: 265px;">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Improved kpis by type</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['Sales', 'Marketing', 'Support']"
                    class="h-75"
                    legend-position="bottom"
                    legend-align="start"
                  />
                </b-card>
              </b-col>
              <b-col cols="4" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm  pb-2"
                  style="height: 100%; "
                >
                  <h4>Completation rate of Kpi by Type</h4>
                  <div
                    class="h-100 flex flex-column "
                    style="justify-content: space-around"
                  >
                    <HorizontalBars
                      label="Sales"
                      :value="23"
                      :tot="40"
                      description="Risposte corrette su risposte totali, per il Planogram. 23 item correttamente inseriti su 40 item totali"
                    />

                    <HorizontalBars
                      label="Marketing"
                      :value="7"
                      :tot="9"
                      description="Risposte corrette su risposte totali, per la Checking List. 7 task completate su 9 risposte totali"
                    />

                    <HorizontalBars
                      label="Support"
                      :value="100"
                      :tot="300"
                      description="Risposte corrette su risposte totali, per l'inserimento degli ordini. 100 ordini inseriti su 300 totali"
                    />
                  </div>
                </b-card>
              </b-col>
              <b-col cols="4" class="mb-5">
                <b-card
                  class="h-100 rounded-xl shadow-sm"
                  style="height: 265px !important;"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Moved Kpis</h4>
                  </div>
                  <BarChart
                    :datasets="stats.mediaKpiPerUtente"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="4" class="mb-5">
                <b-card
                  class="h-100 rounded-xl shadow-sm"
                  style="height: 265px !important;"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Achieved Kpis</h4>
                  </div>
                  <BarChart
                    :datasets="stats.mediaKpiPerUtente"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="4" class="mb-5">
                <b-card
                  class="h-100 rounded-xl shadow-sm"
                  style="height: 265px !important;"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Period comparison</h4>
                  </div>
                  <CombinedChart
                    :datasets="stats.taskCompletateSuAssegnate"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card
            style="background-color: #f7f9ff; border: 5px solid #9faf4e"
            class="my-4 rounded-xl shadow-lg"
          >
            <h2
              class="gfont-400"
              :style="{
                color: '#9faf4e',
                fontSize: '40px',
              }"
            >
              Skills and Continous improvement
              <b-button
                @click="showDetails('kpis')"
                variant="link"
                class="float-right"
                >Details</b-button
              >
            </h2>
            <b-row class="my-4 py-4" align-v="stretch">
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Users that have started the trail',
                      value: 53,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicatore che mostra il % di utenti attivi che hanno completato KPI.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center ;"
                    >
                      53 / 73 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Users that have started at least 1 quiz',
                      value: 23,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicatore che mostra il % di utenti attivi che hanno completato KPI.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center ;"
                    >
                      53 / 73 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Users who completed at least 1 skills',
                      value: 53,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicatore che mostra il % di utenti attivi che hanno completato KPI.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center ;"
                    >
                      53 / 73 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% badges gained',
                      value: 53,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicatore che mostra il % di utenti attivi che hanno completato KPI.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center ;"
                    >
                      53 / 73 <br />
                      badges
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% certifications gained',
                      value: 53,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicatore che mostra il % di utenti attivi che hanno completato KPI.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center ;"
                    >
                      53 / 73 <br />
                      certifications
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col cols="2" class=" mb-5">
                <b-card class="rounded-xl shadow-sm h-100">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Roles</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['User', 'Backoffice', 'Admin']"
                    :legendPosition="'bottom'"
                    :legendAlign="'start'"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="4" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Peak times</h4>
                  </div>
                  <BarChart
                    :datasets="stats.sessioniAttive"
                    :labels="['Morning', 'Afternoon', 'Evening', 'Night']"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="4" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm pb-1"
                  style="min-height: 365px;height: 100%; "
                >
                  <div
                    class="h-100 flex flex-column "
                    style="justify-content: space-around"
                  >
                    <HorizontalBars
                      label="Shift Skill tot value"
                      strong-label
                      :value="37"
                      :tot="40"
                      description="Risposte corrette su risposte totali, per l'accademy. 23 risposte corrette su 40 risposte totali"
                    />

                    <HorizontalBars
                      label="Support"
                      :value="23"
                      :tot="40"
                      description="Risposte corrette su risposte totali, per l'accademy. 23 risposte corrette su 40 risposte totali"
                    />

                    <HorizontalBars
                      label="Management"
                      :value="7"
                      :tot="9"
                      description="Risposte corrette su risposte totali, per Continuous Improvement. 7 risposte corrette su 9 risposte totali"
                    />

                    <HorizontalBars
                      label="Sales"
                      :value="100"
                      :tot="300"
                      description="Risposte corrette su risposte totali, per Sprint Formativi. 100 risposte corrette su 300 risposte totali"
                    />
                  </div>
                </b-card>
              </b-col>
              <b-col cols="4" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm pb-1"
                  style="min-height: 365px;height: 100%; "
                >
                  <div
                    class="w-100 d-flex flex-column justify-content-end align-items-center mb-4"
                  >
                    <b-form-select
                      :options="['Total', 'Support', 'Management', 'Sales']"
                      v-model="selectedSkill"
                    >
                    </b-form-select>
                  </div>

                  <RadarChart
                    v-if="selectedSkill === 'Total'"
                    :datasets="stats.radarSkillsTot.datasets"
                    :labels="stats.radarSkillsTot.labels"
                    class="h-75"
                  />

                  <RadarChart
                    v-if="selectedSkill === 'Support'"
                    :datasets="stats.radarSkillsSupport.datasets"
                    :labels="stats.radarSkillsSupport.labels"
                    class="h-75"
                  />

                  <RadarChart
                    v-if="selectedSkill === 'Management'"
                    :datasets="stats.radarSkillsManagement.datasets"
                    :labels="stats.radarSkillsManagement.labels"
                    class="h-75"
                  />

                  <RadarChart
                    v-if="selectedSkill === 'Sales'"
                    :datasets="stats.radarSkillsSales.datasets"
                    :labels="stats.radarSkillsSales.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="6">
                <b-card
                  style="background-color: #f7f9ff; border: 5px solid #9faf4e"
                  class="my-4 rounded-xl shadow-sm"
                >
                  <h3 class="gfont-400" style="color: #9faf4e">
                    Started skills
                  </h3>
                  <b-row class="my-4 py-4">
                    <b-col cols="12" class="mb-5">
                      <b-card
                        class="h-100 rounded-xl shadow-sm"
                        style="min-height: 265px;"
                      >
                        <BarChart
                          :datasets="stats.mediaCapitoliCompletatiPerUtente"
                          :labels="stats.labels"
                          class="h-75"
                        />
                      </b-card>
                    </b-col>
                    <b-col cols="6" class="mb-5">
                      <b-card
                        class="rounded-xl shadow-sm "
                        style="min-height: 265px; height: 100%"
                      >
                        <div
                          class="w-100 d-flex justify-content-between align-items-center"
                        >
                          <h4>Skills by activity</h4>
                        </div>
                        <PieChart
                          :datasets="stats.ruoli"
                          :labels="['Kpi', 'Task', 'Learning']"
                          class="h-75"
                        />
                      </b-card>
                    </b-col>
                    <b-col cols="6" class="mb-5">
                      <b-card
                        class="rounded-xl shadow-sm "
                        style="min-height: 265px; height: 100%"
                      >
                        <div
                          class="w-100 d-flex justify-content-between align-items-center"
                        >
                          <h4>Skills by type</h4>
                        </div>
                        <PieChart
                          :datasets="stats.ruoli"
                          :labels="['Support', 'Management', 'Sales']"
                          class="h-75"
                        />
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>

              <b-col cols="6">
                <b-card
                  style="background-color: #f7f9ff; border: 5px solid #9faf4e"
                  class="my-4 rounded-xl shadow-sm"
                >
                  <h3 class="gfont-400" style="color: #9faf4e">
                    Completed skills
                  </h3>
                  <b-row class="my-4 py-4">
                    <b-col cols="12" class="mb-5">
                      <b-card
                        class="h-100 rounded-xl shadow-sm"
                        style="min-height: 265px;"
                      >
                        <BarChart
                          :datasets="stats.mediaCapitoliCompletatiPerUtente"
                          :labels="stats.labels"
                          class="h-75"
                        />
                      </b-card>
                    </b-col>
                    <b-col cols="6" class="mb-5">
                      <b-card
                        class="rounded-xl shadow-sm "
                        style="min-height: 265px; height: 100%"
                      >
                        <div
                          class="w-100 d-flex justify-content-between align-items-center"
                        >
                          <h4>Skills by activity</h4>
                        </div>
                        <PieChart
                          :datasets="stats.ruoli"
                          :labels="['Kpi', 'Task', 'Learning']"
                          class="h-75"
                        />
                      </b-card>
                    </b-col>
                    <b-col cols="6" class="mb-5">
                      <b-card
                        class="rounded-xl shadow-sm "
                        style="min-height: 265px; height: 100%"
                      >
                        <div
                          class="w-100 d-flex justify-content-between align-items-center"
                        >
                          <h4>Skills by type</h4>
                        </div>
                        <PieChart
                          :datasets="stats.ruoli"
                          :labels="['Support', 'Management', 'Sales']"
                          class="h-75"
                        />
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card
            style="background-color: #f7f9ff; border: 5px solid #70008f;"
            class="my-4 rounded-xl shadow-lg"
          >
            <h2
              class="gfont-400"
              :style="{
                color: '#70008f',
                fontSize: '40px',
              }"
            >
              Survey
              <b-button
                @click="showDetails('socials')"
                variant="link"
                class="float-right"
                >Details</b-button
              >
            </h2>
            <b-row class="my-4 py-4">
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Users doing surveys',
                      value: 53,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicatore che mostra il % di utenti attivi che hanno completato KPI.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center ;"
                    >
                      53 / 73 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% badges gained',
                      value: 53,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicatore che mostra il % di utenti attivi che hanno completato KPI.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center ;"
                    >
                      53 / 73 <br />
                      badges
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: '% certifications gained',
                      value: 53,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indicatore che mostra il % di utenti attivi che hanno completato KPI.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center ;"
                    >
                      53 / 73 <br />
                      certifications
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col cols="3" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Peak times</h4>
                  </div>
                  <BarChart
                    :datasets="stats.sessioniAttive"
                    :labels="['Morning', 'Afternoon', 'Evening', 'Night']"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="3" class=" mb-5">
                <b-card class="rounded-xl shadow-sm h-100">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Roles</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['User', 'Backoffice', 'Admin']"
                    class="h-75"
                  />
                </b-card>
              </b-col>

              <b-col cols="3" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>To do surveys</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="[
                      'Accademy',
                      'Continous Improvement',
                      'Operative sprints',
                    ]"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="3" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Done surveys</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="[
                      'Accademy',
                      'Continous Improvement',
                      'Operative sprints',
                    ]"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="6" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm pb-1"
                  style="min-height: 365px;height: 100%; "
                >
                  <h4>Survey results</h4>
                  <div
                    class="h-100 flex flex-column "
                    style="justify-content: space-around"
                  >
                    <HorizontalBars
                      label="Survey 360"
                      :value="37"
                      :tot="40"
                      description="Risposte corrette su risposte totali, per l'accademy. 23 risposte corrette su 40 risposte totali"
                    />

                    <HorizontalBars
                      label="Survey 90"
                      :value="23"
                      :tot="40"
                      description="Risposte corrette su risposte totali, per l'accademy. 23 risposte corrette su 40 risposte totali"
                    />

                    <HorizontalBars
                      label="Survey 180"
                      :value="7"
                      :tot="9"
                      description="Risposte corrette su risposte totali, per Continuous Improvement. 7 risposte corrette su 9 risposte totali"
                    />

                    <HorizontalBars
                      label="Feedback"
                      :value="100"
                      :tot="300"
                      description="Risposte corrette su risposte totali, per Sprint Formativi. 100 risposte corrette su 300 risposte totali"
                    />
                  </div>
                </b-card>
              </b-col>
              <b-col cols="4" class="mb-5">
                <b-card
                  class="h-100 rounded-xl shadow-sm"
                  style="min-height: 265px;height: 100%; "
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Provided surveys</h4>
                  </div>
                  <BarChart
                    :datasets="stats.mediaTaskEseguitePerUtente"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>

              <b-col cols="4" class="mb-5">
                <b-card
                  class="h-100 rounded-xl shadow-sm"
                  style="min-height: 265px;height: 100%; "
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Performed surveys</h4>
                  </div>
                  <CombinedChart
                    :datasets="stats.taskCompletateSuAssegnate"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="4" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm pb-1"
                  style="min-height: 365px;height: 100%; "
                >
                  <div
                    class="w-100 d-flex flex-column justify-content-end align-items-center mb-4"
                  >
                    <b-form-select
                      :options="['Total', 'Support', 'Management', 'Sales']"
                      v-model="selectedSkill"
                    >
                    </b-form-select>
                  </div>

                  <RadarChart
                    v-if="selectedSkill === 'Total'"
                    :datasets="stats.radarSkillsTot.datasets"
                    :labels="stats.radarSkillsTot.labels"
                    class="h-75"
                  />

                  <RadarChart
                    v-if="selectedSkill === 'Support'"
                    :datasets="stats.radarSkillsSupport.datasets"
                    :labels="stats.radarSkillsSupport.labels"
                    class="h-75"
                  />

                  <RadarChart
                    v-if="selectedSkill === 'Management'"
                    :datasets="stats.radarSkillsManagement.datasets"
                    :labels="stats.radarSkillsManagement.labels"
                    class="h-75"
                  />

                  <RadarChart
                    v-if="selectedSkill === 'Sales'"
                    :datasets="stats.radarSkillsSales.datasets"
                    :labels="stats.radarSkillsSales.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card
            style="background-color: #f7f9ff; border: 5px solid #00708f;"
            class="my-4 rounded-xl shadow-lg"
          >
            <h2
              class="gfont-400"
              :style="{
                color: '#00708f',
                fontSize: '40px',
              }"
            >
              Social
              <b-button
                @click="showDetails('socials')"
                variant="link"
                class="float-right"
                >Details</b-button
              >
            </h2>
            <b-row class="my-4 py-4">
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'SocialShare users',
                      value: 30,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Gli utenti SocialShare sono gli utenti che hanno partecipato a SocialShare.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      30 / 73 <br />
                      users
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0 shadow-sm"
                  style="height: 265px;"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Shared content and landing pages',
                      value: 7,
                      type: 'text',
                      description:
                        'Indicatore che mostra la media di completamento Kpi per utente.',
                    }"
                    class="mt-4"
                    style="height: 265px;"
                  />
                </b-card>
              </b-col>

              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Convertion rate',
                      value: 299,
                      tot: 334,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indica il tasso di conversione dei contenuti condivisi.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span class=" gfont-400  mt-4" style="font-size: 24px;">
                      expected: 60%
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm p-0"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Average time spent on landing pages',
                      value: 127,
                      tot: 334,
                      type: 'perc',
                      unit: 'secondi',
                      decimals: 0,
                      description:
                        'Indica il tempo medio di permanenza sulle landingpage. Un tempo di permanenza ideale è di 30 secondi.',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center "
                    >
                      127 / 334 <br />
                      time spent
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col cols="4" class="mb-5">
                <b-card class="h-100 rounded-xl shadow-sm">
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Shares on various social channels</h4>
                  </div>
                  <StackedBarChart
                    :datasets="stats.socialStacked"
                    :labels="['Facebook', 'Whatsapp', 'SMS', 'Email']"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="6" class="mb-5">
                <b-card
                  class="h-100 rounded-xl shadow-sm"
                  style="height: 265px !important;"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>
                      Daily content/landingpage shares
                    </h4>
                  </div>
                  <BarChart
                    :datasets="stats.mediaCondivisioniPerUtente"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col cols="6" class="mb-5">
                <b-card
                  class="h-100 rounded-xl shadow-sm"
                  style="height: 265px !important;"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>
                      Daily openings of contents/landing pages
                    </h4>
                  </div>
                  <BarChart
                    :datasets="stats.apertureGiornaliere"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col sm="12" md="6">
          <b-card
            style="background-color: #f7f9ff; border: 5px solid #b95a02;"
            class="my-4 rounded-xl shadow-lg"
          >
            <h2
              class="gfont-400"
              :style="{
                // color: '#E9ECF4',
                // fontWeight: 'bold',
                fontSize: '40px',
                // '-webkit-text-stroke-width': '1px',
                // '-webkit-text-stroke-color': '#D7D7D7',
              }"
            >
              Badges
              <b-button
                @click="showDetails('badges')"
                variant="link"
                class="float-right"
                >Details</b-button
              >
            </h2>

            <b-row class="my-4 py-4">
              <b-col
                md="4"
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm "
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Handled badges',
                      value: 73,
                      tot: 73,
                      type: 'perc',
                      unit: 'badge',
                      decimals: 0,
                      description:
                        'Indica il numero di badge monitorati dalla piattaforma',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      73 / 73 <br />
                      badge
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                md="4"
                cols="2"
                order-md="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm "
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Avarage badge per user',
                      value: 13,
                      tot: 73,
                      type: 'perc',
                      unit: 'badge',
                      decimals: 0,
                      description:
                        'Indica la media di badge ottenuta per utente',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      13 / 73 <br />
                      badge
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col
                md="6"
                cols="2"
                order-md="4"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Most Won Badge: [Feedback Sample]',
                      value: 43,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indica la percentuale più alta di badge ottenuta per utente',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      86 / 146 <br />
                      trys
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                md="6"
                cols="2"
                order-md="4"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Hardest Badge to Earn: [Perfect Campaign]',
                      value: 3,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indica la percentuale più bassa di badge ottenuta per utente',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      6 / 146 <br />
                      trys
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col md="8" cols="4" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Badges by type</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['Excellence', 'Completion', 'Participation']"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col md="8" cols="4" order-md="3" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Handled badges by type</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['User', 'Backoffice', 'Admin']"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col md="12" cols="8" order="6" class="mb-5">
                <b-card
                  class="h-100 rounded-xl shadow-sm"
                  style="height: 265px !important;"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Redeemed badges by date</h4>
                  </div>
                  <BarChart
                    :datasets="stats.badgeRiscattati"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col sm="12" md="6">
          <b-card
            style="background-color: #f7f9ff; border: 5px solid #00708f;"
            class="my-4 rounded-xl shadow-lg "
          >
            <h2
              class="gfont-400"
              :style="{
                // color: '#E9ECF4',
                // fontWeight: 'bold',
                fontSize: '40px',
                // '-webkit-text-stroke-width': '1px',
                // '-webkit-text-stroke-color': '#D7D7D7',
              }"
            >
              Awards
              <b-button
                @click="showDetails('awards')"
                variant="link"
                class="float-right"
                >Details</b-button
              >
            </h2>

            <b-row class="my-4 py-4">
              <b-col
                md="4"
                cols="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm "
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Handled awards',
                      value: 41,
                      tot: 41,
                      type: 'perc',
                      unit: 'awards',
                      decimals: 0,
                      description:
                        'Indica la percentuale di awards monitorati dalla piattaforma',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      41 / 41 <br />
                      awards
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                md="4"
                cols="2"
                order-md="2"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm "
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Avarage awards per user',
                      value: 4,
                      tot: 41,
                      type: 'perc',
                      unit: 'badge',
                      decimals: 0,
                      description:
                        'Indica la percentuale di awards monitorati per utente',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      4 / 41 <br />
                      awards
                    </span>
                  </div>
                </b-card>
              </b-col>

              <b-col
                md="6"
                cols="2"
                order-md="4"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Most won award: [Full profile]',
                      value: 73,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indica la percentuale più alta di awards monitorati per utente',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      73 / 73 <br />
                      trys
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                md="6"
                cols="2"
                order-md="4"
                class="flex align-items-center justify-content-center mb-5"
              >
                <b-card
                  class="w-100 h-100 flex justify-content-center align-items-center rounded-xl shadow-sm"
                >
                  <IndicatorDisplay2
                    :indicator="{
                      title: 'Hardest Award to Get: [Sales Level 1000]',
                      value: 9,
                      tot: 73,
                      type: 'perc',
                      unit: '%',
                      decimals: 2,
                      description:
                        'Indica la percentuale più bassa di awards monitorati per utente',
                    }"
                  />
                  <div class=" flex justify-content-center">
                    <span
                      class=" gfont-400  mt-4"
                      style="font-size: 24px; text-align: center"
                    >
                      18 / 146 <br />
                      trys
                    </span>
                  </div>
                </b-card>
              </b-col>
              <b-col md="8" cols="4" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Award by type</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['Excellence', 'Completion', 'Participation']"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col md="8" cols="4" order-md="3" class="mb-5">
                <b-card
                  class="rounded-xl shadow-sm "
                  style="min-height: 265px; height: 100%"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Handled awards by role</h4>
                  </div>
                  <PieChart
                    :datasets="stats.ruoli"
                    :labels="['User', 'Backoffice', 'Admin']"
                    class="h-75"
                  />
                </b-card>
              </b-col>
              <b-col md="12" cols="8" order-md="6" class="mb-5">
                <b-card
                  class="h-100 rounded-xl shadow-sm"
                  style="height: 265px !important;"
                >
                  <div
                    class="w-100 d-flex justify-content-between align-items-center"
                  >
                    <h4>Redeemed awards by date</h4>
                  </div>
                  <BarChart
                    :datasets="stats.badgeRiscattati"
                    :labels="stats.labels"
                    class="h-75"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card
            style="background-color: #f7f9ff; border: 5px solid #005f52;"
            class="my-4 rounded-xl shadow-lg"
          >
            <h2
              class="gfont-400"
              :style="{
                fontSize: '40px',
              }"
            >
              Geographic distribution
            </h2>

            <b-row class="my-4">
              <b-col>
                <b-form-group label="Seleziona la prima colonna">
                  <b-form-select v-model="firstColumn" @change="getStats">
                    <b-form-select-option
                      v-for="option in columns"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Seleziona la seconda colonna">
                  <b-form-select v-model="secondColumn" @change="getStats">
                    <b-form-select-option
                      v-for="option in columns"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="my-4 py-4" align-v="stretch">
              <b-col>
                <b-card class="rounded-xl shadow-sm">
                  <div id="google_chart_bubble_map"></div>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <b-modal
      id="dataDetails"
      size="xl"
      scrollable
      centered
      hide-footer
      hide-header
    >
      <div
        class="d-flex flex-column justify-content-center align-items-center "
      >
        <b-card class="w-100 mb-4">
          <h2>Filtri</h2>

          <b-row>
            <b-col
              cols="12"
              md="3"
              v-for="(field, i) in fieldsModalDetails"
              :key="i"
              class="mb-4"
            >
              <div class="w-100 d-flex flex-column">
                <span>{{
                  camelToNormalCase(field.key != "avatar" ? field.key : "ID")
                }}</span>
                <b-form-select v-if="Math.random() >= 0.5">
                  <b-form-select-option value="Lorem">
                    Lorem
                  </b-form-select-option>

                  <b-form-select-option value="Ipsum">
                    Impsum
                  </b-form-select-option>

                  <b-form-select-option value="Dolor">
                    Dolor
                  </b-form-select-option>

                  <b-form-select-option value="Sit">
                    Sit
                  </b-form-select-option>

                  <b-form-select-option value="Amet">
                    Amet
                  </b-form-select-option>
                </b-form-select>
                <b-form-input v-else />
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-table
          class="w-100"
          striped
          hover
          head-variant="dark"
          sticky-header="80vh"
          :items="dataModalDetails"
          :fields="fieldsModalDetails"
        >
          <template #cell(avatar)="data">
            <img :src="data.value" style="width: 120px; height: 120px" />
          </template>

          <template #cell(stato)="data">
            <h5 style="margin: 0;">
              <b-badge v-if="data.value == 'Attivo'" variant="success">{{
                data.value
              }}</b-badge>
              <b-badge v-else variant="light">{{ data.value }}</b-badge>
            </h5>
          </template>

          <template #cell(ultimoLog)="data">
            <pre>{{ data.value }}</pre>
          </template>

          <template #cell(percGeneraleCompletamentoKpi)="data">
            <b-progress :value="data.value"></b-progress>
          </template>

          <template #cell(socialPiuUtilizzato)="data">
            <!-- options: 'Facebook', 'Twitter', 'Instagram', 'LinkedIn' -->

            <b-icon
              :key="data.value"
              :icon="socialIcon[data.value]"
              class="mr-2 "
              style="font-size: 30px !important;"
            ></b-icon>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
b-card {
  h2 {
    font-family: "Anton SC", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
}

.filtri-container {
  background: #ffffff;
  border-radius: 20px;
  padding: 30px 40px;
  /* border: 4px solid #005f52; */
}

/* B-MODAL */

.modal-header {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.modal-title {
  font-weight: bold;
}
.modal-body {
  padding-top: 0;
}
.dataset-container {
  min-width: 200px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  margin-right: 15px;
  margin-bottom: 15px;
  background-color: #f8f9fa;
}
.dataset-label {
  font-weight: bold;
  margin-bottom: 10px;
}
.dataset-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #e9ecef;
}
.dataset-item:last-child {
  border-bottom: none;
}

.item-value {
  font-weight: bold;
  font-style: italic;
}
</style>
